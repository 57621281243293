<template>
   <div class="main">
      <mtop />
      <div class="banner">
         <div class="banimg">
            <!-- <img src="../assets/indeximg/about1.png" alt="" /> -->
            <h1 class="title">星润科技</h1>
            <h2 class="tip">通过技术服务 成就百万客户</h2>
         </div>
      </div>
      <div class="main_box">
         <div class="box_left">
            <div class="left_item itemact" @click="clickNav(1)" :class="{itemact1:firstIndex==1}">
               <div>公司简介</div>
               <div>Company</div>
            </div>
             <div class="left_item" @click="clickNav(2)" :class="{itemact2:firstIndex==2}">
               <div>员工天地</div>
               <div>employee</div>
            </div>
            <div class="left_item" @click="clickNav(3)" :class="{itemact3:firstIndex==3}">
               <div>发展历程</div>
               <div>course</div>
            </div>
            <div class="left_item" @click="clickNav(4)" :class="{itemact4:firstIndex==4}">
               <div>公司位置</div>
               <div>position</div>
            </div>
         </div>
         <div class="box_right">
            <div class="right_box1">
               <h1 class="right_title">公司简介</h1>
               <h2 class="right_tip">多年品质坚守，用心服务客户</h2>
               <div class="right_con">
                  <div class="img">
                     <img src="../assets/indeximg/about11.png" alt="" />
                  </div>
                  <div class="con">
                     <div>
                        北京星润时代网络科技有限公司(简称:星润科技)成立于2013年，总部位于北京，作为互联网及区块链技术持续创新的服务商，星润科技经历了近10年的技术沉淀，目前拥有新零售电商系统、数字资产交易系统，多链钱包SAAS系统，数字资产智能量化机器人，分布式存储挖矿系统等多条成熟的产品线，截至目前，星润科技以为1000+客户提供互联网+区块链技术服务。
                     </div>
                     <div>
                        北京星润时代网络科技有限公司——全力打造新一代互联网软件开发品牌。
                        以“互联网+”思维，致力于为企业提供更好的互联网产品和解决方案。
                        专注提供互联网技术的企业、通过软件技术、
                        大数据分析及人工智能的创新融合，将各个应用系统数据进行梳理和分析，为企业发展及战略决策提供数据支持，同时为客户降低运营成本，提高工作效率，提升企业竞争力
                     </div>
                  </div>
               </div>
            </div>
            <div class="right_box2">
               <h1 class="right_title">员工天地</h1>
               <h2 class="right_tip">与员工携手创造辉煌</h2>
               <div class="right_con">
                  <img src="../assets/indeximg/staff1.png" alt="">
                  <img src="../assets/indeximg/staff2.png" alt="">
                  <img src="../assets/indeximg/staff3.png" alt="">
                  <img src="../assets/indeximg/staff4.png" alt="">
               </div>
            </div>
            <div class="right_box3">
               <h1 class="right_title">企业发展</h1>
               <h2 class="right_tip">八年稳建运营 砥砺前行</h2>
               <div class="step_box">
                  <div class="step_left step_item">
                    <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2021年01月</div>
                        <div>获得中关村高新技术企业认证</div>
                     </div>
                     </div>
                  </div>
                  <div class="step_right step_item">
                      <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2021年05月</div>
                        <div>KingTrader智能量化系统上线</div>
                     </div>
                     </div>
                  </div>
                  <div class="step_left step_item">
                    <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2019年09月</div>
                        <div>涉足数字资产智能量化领域</div>
                     </div>
                     </div>
                  </div>
                   <div class="step_right step_item">
                      <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2019年05月</div>
                        <div>涉足分布式储存领域 正式上线“星科算力APP”</div>
                     </div>
                     </div>
                  </div>
                   <div class="step_left step_item">
                    <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2017年07月</div>
                        <div>通过经济改造孵化平台上线</div>
                     </div>
                     </div>
                  </div>
                  <div class="step_right step_item">
                      <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2016年01月</div>
                        <div>微小赚升级小程序端正式上线</div>
                     </div>
                     </div>
                  </div>
                   <div class="step_left step_item">
                    <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2015年05月</div>
                        <div>微客赚代理云仓系统正式上线</div>
                     </div>
                     </div>
                  </div>
                  <div class="step_right step_item">
                      <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2014年04月</div>
                        <div>微客赚分销系统正式上线</div>
                     </div>
                     </div>
                  </div>
                   <div class="step_left step_item">
                    <div class="item">
                     <div class="dian"></div>
                     <div class="kuai">
                        <div>2013年11月</div>
                        <div>北京星润时代科技有限公司正式成立</div>
                     </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="right_box4">
               <h1 class="right_title">公司位置</h1>
               <h2 class="right_tip">无论天涯海角我们的服务与您相伴</h2>
               <div class="right_img">
                  <!-- <img src="" alt=""> -->

                  <baidu-map
                     :center="center"
                     :zoom="zoom"
                     @ready="handler"
                     style="height: 600px"
                     @click="getClickInfo"
                     :scroll-wheel-zoom="true"
                  >
                  </baidu-map>
               </div>
            </div>
         </div>
      </div>
     

      <mbottom />
   </div>
</template>

<script>
import { connectTron, invests, walletOfOwner, tokenURI } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
   data() {
      return {
          heightList:[],
         center: { lng: 111, lat: 111 }, //lng代表纬度 lat代表经度
         zoom: 19,
          firstIndex:1,
         items: [
            {
               tag: "2018-01-12",
               content: "hallo",
            },
            {
               tag: "2018-01-13",
               color: "#dcdcdc",
               type: "circle",
               content: "world",
            },
            {
               type: "star",
               tag: "2018-01-14",
               htmlMode: true,
               content: 111,
            },
         ],
      };
   },

   components: {
      mtop,
      mbottom,
   },

   mounted() {
        let divgao=document.querySelector(".right_box1").offsetTop;
        this.heightList.push(divgao);
        window.addEventListener('scroll',this.handlerScroll,true)
   },
   watch: {
      "$store.getters.sid": function (val) {
         this.handleFiexdToolItem(val);
      },
      deep: true,
      immediate: true,
   },
   created() {
   },
   methods: {
      clickNav(index){
        this.firstIndex=index;
        var navPage=document.querySelector(".right_box"+index)
        // const wrap=this.sysWrap.wrap;
        // wrap.scrollTop=navPage.offsetTop;
        window.scrollTo({
          'top':navPage.offsetTop,
            'behavior':'smooth'
        })

        

      },
      handler({ BMap, map }) {
         var point = new BMap.Point(111, 111);
         map.centerAndZoom(point, 19);
         var marker = new BMap.Marker(point); // 创建标注
         map.addOverlay(marker); // 将标注添加到地图中
         var circle = new BMap.Circle(point, 1, {
            strokeColor: "Red",
            strokeWeight: 1,
            strokeOpacity: 1,
            Color: "Red",
            fillColor: "#f03",
         });
         map.addOverlay(circle);
      },
      getClickInfo(e) {
         console.log(e.point.lng);
         console.log(e.point.lat);
         this.center.lng = e.point.lng;
         this.center.lat = e.point.lat;
      },

      readDetail() {
         this.$router.replace({ path: "/help2" });
      },
      goHelpMore(i) {
         console.log(i);
         this.$router.replace({ path: "/help1?index=" + i });
      },
      wallet() {
         var i = 0;
         var a = setInterval(() => {
            if (typeof window.ethereum !== "undefined") {
               connectTron().then(
                  (info) => {
                     clearInterval(a);
                     if (info[0].indexOf("0x") == 0) {
                        if (info[0] != this.$store.getters.userWallet) {
                           this.buy = true;
                        }
                        this.$store.commit("userWallet", info[0]);
                     } else {
                        this.$toast(
                           "Use the ETH address to log in to the game"
                        );
                     }
                  },
                  (e) => {
                     this.$toast(e.message);
                  }
               );
            }
            if (i > 5) {
               clearInterval(a);
            }
            i++;
         }, 1000);
      },
      _isMobile() {
         let flag = navigator.userAgent.match(
            /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
         );
         return flag;
      },
      topage(e) {
         this.$router.replace({ path: e });
         this.$store.commit("sid", "");
      },
      tobuy() {
         location.href = "https://opensea.io/collection/cupid-love-metaverse";
      },
      up() {
         if (this.sqloading) {
            return;
         }
         this.buynum = Number(this.buynum) + 1;
      },
      dow() {
         if (this.sqloading) {
            return;
         }
         this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
      },
      close() {
         this.shows = false;
      },
      handleFiexdToolItem(e) {
         if (!e) {
            return;
         }
         this.$nextTick(() => {
            document.getElementById(e).scrollIntoView({
               behavior: "smooth",
               block: "start",
               inline: "nearest",
            });
         });
      },
      buys() {
         // this.list.length=this.buynum
         // this.shows=true
         //  console.log("lists--",this.list);
         // this.getList()
         // return
         this.wallet();
         if (this.buynum > 0) {
            if (!this.sqloading) {
               this.sqloading = true;
               invests(this.$store.getters.userWallet, this.buynum).then(
                  (res) => {
                     if (res) {
                        this.list.length = this.buynum;
                        this.shows = true;
                        this.getList();
                     }
                     this.sqloading = false;
                  }
               );
            }
         }
      },
      getList() {
         // this.list=[]
         walletOfOwner(this.$store.getters.userWallet).then((res) => {
            // console.log("res",res);
            var list = res.slice(-this.buynum);
            // console.log("list",list);
            list.forEach((el, i) => {
               // console.log('el',el,i);
               this.getUrl(el, i);
            });
         });
      },
   },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";
.main {
   background: #f7f7f7;
   min-height: 100vh;
   max-width: 100%;
   min-width: 1200px;
   width: 100%;
   .banner {
      max-width: 100%;
      min-width: 1200px;
      width: 100%;
      margin-bottom: 40px;
      .banimg {
         max-width: 100%;
         min-width: 1200px;
         width: 100%;
         height: 510px;
         position: relative;
         text-align: left;
         background: url("../assets/indeximg/about1.png");
         background-size: 100% 100%;
         //  & > img {
         //     min-width: 1360px;
         //     max-width: 100%;
         //     width:100%;
         //     height: 530px;
         //     position: absolute;
         //     left: 0px;
         //     bottom: 28px;
         //  }
         .title {
            width: 1200px;
            position: absolute;
            top: 321px;
            left: 50%;
            margin-left: -600px;
            font-size: 40px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
         }
         .tip {
            width: 1200px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            top: 388px;
            left: 50%;
            margin-left: -600px;
         }
      }
   }
   .main_box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .box_left {
         display: flex;
         flex-direction: column;
         align-items: center;
         margin-right: 10px;
         background: #fff;
         height: 304px;
         .left_item {
            width: 240px;
            height: 77px;
            border-bottom: 1px solid #f7f7f7;
            box-sizing: border-box;
            padding: 20px;
            div:nth-child(1) {
               font-size: 18px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
            }
            div:nth-child(2) {
               font-size: 12px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #fff;
            }
         }
         .left_item:hover {
            background: #0052da;
            div {
               color: #fff !important;
            }
         }
         .itemact1 {
            background: url("../assets/indeximg/leftimg/p3.png");
            background-size: 100% 100%;
            div {
               color: #fff !important;
            }
         }
         
         .itemact1:hover {
            background: url("../assets/indeximg/leftimg/p3.png");
            background-size: 100% 100%;
         }
         .itemact3{
           background: url("../assets/indeximg/leftimg/2.png");
            background-size: 100% 100%;
            div {
               color: #fff !important;
            }
         }
         .itemact3:hover {
            background: url("../assets/indeximg/leftimg/2.png");
            background-size: 100% 100%;
         }
         .itemact2{
          background: url("../assets/indeximg/leftimg/4.png");
            background-size: 100% 100%;
            div {
               color: #fff !important;
            }
         }
         .itemact2:hover {
            background: url("../assets/indeximg/leftimg/4.png");
            background-size: 100% 100%;
         }
          .itemact4{
          background: url("../assets/indeximg/leftimg/3.png");
            background-size: 100% 100%;
            div {
               color: #fff !important;
            }
         }
         .itemact4:hover {
            background: url("../assets/indeximg/leftimg/3.png");
            background-size: 100% 100%;
         }
      }
      .box_right {
         background: #fff;
         box-sizing: border-box;
         padding: 32px 28px;
         display: flex;
         flex-direction: column;
         text-align: left;
         cursor: default;
         .right_box1 {
            text-align: center;
            .right_title {
               font-size: 28px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
            }
            .right_tip {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #999999;
               // margin-top:13px;
               // line-height: 50px;
            }
            .right_con {
               display: flex;
               margin-top: 45px;
               justify-content: space-between;
               .img {
                  width: 314px;
                  height: 230px;
                  margin-right: 20px;
                  margin-top: 6px;
               }
               .con {
                  width: 556px;
                  height: 229px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                  line-height: 23px;
                  text-align: left;
               }
            }
         }
          .right_box2 {
              margin-top:20px;
            text-align: center;
            .right_title {
               font-size: 28px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
              
            }
            .right_tip {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #999999;
            }
            .right_con {
               display: flex;
               margin-top: 35px;
               justify-content: space-between;
               flex-wrap: wrap;
               img{
                  width:50%;
               }
               
            }
         }
         .right_box4 {
            text-align: center;
            //  margin-top:50px;
            .right_title {
               margin-top: 50px;
               font-size: 28px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
            }
            .right_tip {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #999999;
               margin-bottom:40px;
               // line-height: 60px;
            }
            .right_img {
               width: 900px;
            }
         }
         .right_box3 {
            text-align: center;
            margin-top: 50px;
            .right_title {
               font-size: 28px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
            }
            .right_tip {
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #999999;
               margin-bottom: 40px;
               // line-height: 60px;
            }
            .step_box {
               width: 100%;
              //  border: 1px solid blue;
               height: 667px;
               // border: 1px solid red;
               .step_item {
                  width: 100%;
                  min-height: 60px;
                  // background: pink;
                  .item{
                    width:50%;
                     min-height: 60px;
                     position: relative;
                    //  border-left: 3px solid #cecece;
                      .dian {
                    //  width: 10px;
                    //  height: 10px;
                    //  background: #cecece;
                    //  border-radius: 50%;
                    //  position:absolute;
                    //  top: 50%;
                    //  left: -5px;
                    //  margin-top: -5px;
                    //  z-index: 99;

                  }
                  .kuai{
//                      background:red;
//                      height: 100%;
//                      position:absolute;
//                      width: 100%;
//                      box-sizing: border-box;
//                      padding: 8px;
//                      div:nth-child(1){
//                        font-size: 16px;
// font-family: Microsoft YaHei;
// font-weight: bold;
// color: #FFFFFF;
//                      }
//                       div:nth-child(2){
//                         font-size: 16px;
// font-family: Microsoft YaHei;
// font-weight: bold;
// color: #FFFFFF;
//                       }
                  }
                  }
                  
               }
               .step_right{
                  width: 100%;
                  min-height: 70px;
                  .item{
                    width:50%;
                    float:left;
                    min-height: 70px;
                    position: relative;
                    border-right: 2px solid #cecece;
                    transform: translateX(-2px);
                      .dian {
                     width: 10px;
                     height: 10px;
                     background: #cecece;
                     border-radius: 50%;
                     position:absolute;
                     top: 50%;
                     right: -6px;
                     margin-top: -5px;
                     z-index: 99;

                  }
                  .kuai{
                     background: #FF8460;
                     border-radius: 2px;
                     height: 100%;
                     position:absolute;
                     width: 415px;
                     box-sizing: border-box;
                     padding: 8px;
                   transform: translateX(13px);
                     div:nth-child(1){
                       font-size: 16px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #FFFFFF;
text-align: right;
                     }
                      div:nth-child(2){
                        font-size: 16px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #FFFFFF;
text-align: left;
                      }
                  }
                  }
               }
                .step_left{
                     width: 100%;
                  min-height: 70px;
                  // border: 1px solid red;

                  .item{
                    width:50%;
                    float: right;
                     min-height: 70px;
                     position: relative;
                      border-left: 2px solid #cecece;
                      .dian {
                     width: 10px;
                     height: 10px;
                     background: #cecece;
                     border-radius: 50%;
                     position:absolute;
                     top: 50%;
                     left: -6px;
                     margin-top: -5px;
                     z-index: 99;

                  }
                  .kuai{
                     background: #4D85F4;
                      border-radius: 2px;
                     height: 100%;
                     position:absolute;
                    width: 415px;
                     box-sizing: border-box;

                     padding: 13px;
                     margin-left:20px;
                     div:nth-child(1){
                       font-size: 16px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #FFFFFF;   
text-align: left;
                     }
                      div:nth-child(2){
                        font-size: 16px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #FFFFFF;
text-align: left;
                      }
                  }
                  }
               }
            }
            .line_box {
               width: 666px;
               margin: 0 auto;
               height: 300px;
               .clearfix {
                  width: 330px;
                  position: relative;
                  width: 100%;
                  border-left: 3px solid #eee;
                  background: pink;
                  .fix1 {
                     width: 330px;
                     position: relative;
                     width: 100%;
                     border-left: 3px solid #eee;
                     .card {
                        width: 300px;
                        padding: 15px;
                        background: #0052da;
                     }
                  }
               }
            }
            .block {
               height: 667px;
               border: 1px solid red;

               .line {
                  background: red;
                  width: 50%;
                  float: left;
                  .line_item {
                     background: blue;
                     height: 40px;
                     transform: translateX(100%);
                     .card {
                        background: pink;
                        float: left;
                        height: 30px;
                     }
                  }
                  .line_item1 {
                     background: pink;
                     height: 40px;
                     transform-origin: center;
                     transform: rotate(180deg);
                  }
               }
            }
         }
      }
   }
}
</style>